body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.status {
  padding-top: 2rem;
  height: 338px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.risk {
  background: linear-gradient(180deg, #FF0053 0%, #C22E00 100%);
}

.poor {
  background: linear-gradient(180deg, #FCA600 0%, #C86000 100%);
}

.fair {
  background: linear-gradient(180deg, #D0DAE9 0%, #544D47 100%);
}

.good {
  background: linear-gradient(180deg, #00D3ED 0%, #0061FF 100%);
}

.spree {
  background: linear-gradient(180deg, #A8FF00 0%, #00A925 100%);
}

.statusRing {
  position: absolute;
  transform: rotate(-106deg);
}

.statusValue {
  margin-top: 4.5rem;
  font-size: 3.5rem;
  font-weight: 600;
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.statusDescription {
  padding-top: 4rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
}

.main {
  position: absolute;
  width: 23rem;
  padding-top: 3rem;
  left: calc(50% - 23rem/2 + 6.5px);
  top: 23rem;
}

.row {
  clear: both;
  flex: none;
  order: 0;
  margin-bottom: 3rem;
}

.title {
  font-size: 1rem;
  font-weight: 750;
  text-transform: uppercase;
}

.gauge {
  width: 7rem;
  float: left;
  margin-right: .65rem;
  margin-bottom: 2rem;
}

h1, h2 {
  margin-top: .5rem;
  font-weight: 500;
}

h3 {
  text-align: center;
}

h3.value {
  margin-top: 2.5rem;
  font-size: 1.5rem;
}

h3.label {
  margin-top: 2.5rem;
  font-size: 1.3rem;
  color: #999999;
  text-transform: uppercase;
}

.circularProgressbar {
  position: absolute;
  margin-top: 0.65rem;
  margin-left: 0.65rem;
  width: 5.5rem;
  height: 5.5rem;
}

.progressbar {
  position: absolute;
  margin-top: 0.65rem;
  margin-left: 2rem;
  width: 3rem;
  height: 2.5rem;
}

.trail, .path {
  border-radius: 10px;
}

button {
  -webkit-appearance: none;
  outline: none;
  background-color: #0061FF;
  color: #FFF;
  border-radius: .5rem;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

button:active {
  transform: scale(.97);
  background-color: #005BEE;
}

#startLandbot {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}